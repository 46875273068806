import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import cn from 'classnames'

import { ServiceType } from 'store/service'

import App from 'components/App'

import 'react-tooltip/dist/react-tooltip.css'
import styles from './HelperServiceDescription.module.scss';

type Props = {
  item: ServiceType,
}

const HelperServiceDescription: React.FC<Props> = ({ item }) => { 
  const [hasTooltip, setHasTooltip] = useState(false)

  const textRef = useRef<HTMLDivElement>(null)
  const hiddenTextRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (textRef.current && hiddenTextRef.current) {
      const { clientWidth } = textRef.current
      const hiddenTextWidth = hiddenTextRef.current.scrollWidth

      setHasTooltip(hiddenTextWidth > clientWidth)
    }
  }, [item.description])

  return item.description !== '' ? (
    <>
      <div
        ref={textRef} 
        onClick={(e) => e.stopPropagation()}
        style={{ width: '100%' }}
        data-tooltip-id={`tooltip-service-${item.id}`}
      >
        <App.Flex fullWidth className={styles.description}>
          <App.Flex fullWidth className={cn(styles.inner, {[styles.hasTooltip]: hasTooltip})}>
            <App.Text nowrap xs height={1.2}>
              {item.description}
            </App.Text>
          </App.Flex>
        </App.Flex>
      </div>

      <div ref={hiddenTextRef} className={styles.hidden}>
        <App.Text nowrap xs height={1.2}>
          {item.description}
        </App.Text>
      </div>

      {hasTooltip && (
        <Tooltip id={`tooltip-service-${item.id}`} place="bottom" style={{ borderRadius: 8 }}>
          <App.Flex width={200}>
            <App.Text xs white>{item.description}</App.Text>
          </App.Flex>
        </Tooltip>
      )}
    </>
  ) : null
}

export default HelperServiceDescription