import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { useAppDispatch, useAppSelector } from 'store'
import $business from 'store/business'
import $service, { ServiceType } from 'store/service'
import $resource from 'store/resource'

import App from 'components/App'
import Helper from 'components/Helper'

import styles from './AppointmentServices.module.scss'

export type Props = {
  onScroll?: (value: boolean) => void
}

const AppointmentServices: React.FC<Props> = ({ onScroll }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const design = useAppSelector($business.get.design)
  const settings = useAppSelector($business.get.settings)
  const services = useAppSelector($service.get.filtered)
  const selectedServiceIds = useAppSelector(({ $service }) => $service.selectedIds)

  useEffect(() => {
    if (selectedServiceIds.length) {
      const excludeIds: number[] = []
      selectedServiceIds.forEach((id: number) => {
        const service = services.find((item: ServiceType) => item.id === id)
        if (!service) {
          excludeIds.push(id)
        }
      })

      const temp = selectedServiceIds.filter((id: number) => !excludeIds.includes(id))
      dispatch($service.set.selectedIds(temp))
      if (!temp.length) {
        dispatch($resource.set.selectedId(null))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services])

  const handleToggle = (id: number | string) => {
    if (isDisabled(id)) {
      return
    }

    const numberId = Number(id)
    let temp = [...selectedServiceIds]
    if (temp.includes(numberId)) {
      temp.splice(selectedServiceIds.indexOf(numberId), 1)
    } else {
      if (settings.allow_multiple_services) {
        temp.push(numberId)
      } else {
        temp = [numberId]
      }

      if (onScroll) {
        onScroll(true)
      }
    }

    dispatch($service.set.selectedIds(temp))
  }

  const isDisabled = (id: number | string) => {
    if (selectedServiceIds.length && settings.allow_multiple_services) {
      const selectedService = services.find((item: ServiceType) => item.id === selectedServiceIds[0])
      const targetService = services.find((item: ServiceType) => item.id === id)
  
      if (selectedService?.resources && targetService?.resources) {
        const hasCommonResource = selectedService.resources.some((resourceId: number) => 
          targetService.resources.includes(resourceId)
        )
  
        return !hasCommonResource
      }
    }
  
    return false
  }

  return services.length ? (
    <App.Flex column gap={4}>
      <App.Text bold height={1}>{t('Select service')}:</App.Text>

      <App.Flex column gap={3}>
        {services.map((item: ServiceType) => {
          const image = item.item_image ?? design.logo ?? null
          return (
            <App.Card key={item.id} small active={selectedServiceIds.includes(item.id) && !isDisabled(item.id)} disabled={isDisabled(item.id)} value={item.id} onClick={handleToggle}>
              <App.Flex row aCenter jBetween gap={1}>
                <App.Flex row gap={3} aCenter flex={1}>
                  <App.Flex center className={cn(styles.image, {[styles.logo]: !item.item_image})}>
                    {image ? (
                      <img src={image} alt="" />
                    ) : null}
                  </App.Flex>

                  <App.Flex column gap={2} flex={1}>
                    <App.Flex column gap={1} fullWidth>
                      <App.Text small bold height={1.2}>{item.name}</App.Text>
                      <Helper.ServiceDescription item={item} />
                    </App.Flex>

                    {(settings.display_duration && item.real_duration > 0) || (item.price && settings.display_price) ? (
                      <App.Flex column gap={1}>
                        <App.Text xs gray>{t('Details')}:</App.Text>

                        <App.Flex wrap row gap={2}>
                          {settings.display_duration && item.real_duration > 0 ? (
                            <App.Tag icon="clock">
                              <span>{item.formated_duration.h > 0 ? (item.formated_duration.h + t('h') + ' ') : null}</span>
                              <span>{item.formated_duration.m}{t('m')}</span>
                            </App.Tag>
                          ) : null}

                          {settings.display_price && item.price ? (
                            <App.Tag><span>₪</span>{item.price}</App.Tag>
                          ) : null}

                          {item.card_balance ? (
                            <App.Tag>
                              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgY2xhc3M9ImJpIGJpLXRpY2tldC1maWxsIj4KCiA8Zz4KICA8dGl0bGU+YmFja2dyb3VuZDwvdGl0bGU+CiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHBhdGggZmlsbD0iIzBkYzE0MyIgaWQ9InN2Z18xIiBkPSJtMS41LDNhMS41LDEuNSAwIDAgMCAtMS41LDEuNWwwLDEuNWEwLjUsMC41IDAgMCAwIDAuNSwwLjVhMS41LDEuNSAwIDEgMSAwLDNhMC41LDAuNSAwIDAgMCAtMC41LDAuNWwwLDEuNWExLjUsMS41IDAgMCAwIDEuNSwxLjVsMTMsMGExLjUsMS41IDAgMCAwIDEuNSwtMS41bDAsLTEuNWEwLjUsMC41IDAgMCAwIC0wLjUsLTAuNWExLjUsMS41IDAgMCAxIDAsLTNhMC41LDAuNSAwIDAgMCAwLjUsLTAuNWwwLC0xLjVhMS41LDEuNSAwIDAgMCAtMS41LC0xLjVsLTEzLDB6Ii8+CiA8L2c+Cjwvc3ZnPg==" alt="" />
                              {item.card_balance}
                            </App.Tag>
                          ) : null}
                        </App.Flex>
                      </App.Flex>
                    ) : null}
                  </App.Flex>
                </App.Flex>

                <App.Flex center className={cn(styles.checkbox, {[styles.active]: selectedServiceIds.includes(item.id)})}>
                  <App.Icon icon="check" white />
                </App.Flex>
              </App.Flex>
            </App.Card>
          )}
        )}
      </App.Flex>
    </App.Flex>
  ) : null
}

export default AppointmentServices