import React, { useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { gsap } from 'gsap'
import cn from 'classnames'

import styles from './AppDialog.module.scss'

export type Props = {
  children?: React.ReactNode
  open: boolean
  doNotClose?: boolean
  actions?: React.ReactNode
  scrollDependensies?: any
  style?: React.CSSProperties
  scrollDown?: boolean
  whiteBack?: boolean
  scrollId?: string
  className?: string
  noAnimation?: boolean
  onScrollEnd?: (value: boolean) => void
  onClose: () => void
}

const AppDialog: React.FC<Props> = (props) => {
  const [opened, setOpened] = useState(false)
  const [scrollBottom, setScrollBottom] = useState(false)

  const layoutAnimation = useRef<gsap.core.Tween>()
  const contentAnimation = useRef<gsap.core.Tween>()

  const layout = useRef(null)
  const content = useRef(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const isMounted = useRef(true)

  useLayoutEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
      layoutAnimation.current?.kill()
      contentAnimation.current?.kill()
    }
  }, [])

  useLayoutEffect(() => {
    if (props.scrollDown && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [props.scrollDown])

  useLayoutEffect(() => {
    if (scrollRef.current) {
      processShadow(scrollRef.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scrollDependensies])

  useLayoutEffect(() => {
    if (props.open) {
      if (!opened) {
        document.body.classList.add('modal-open')

        if (!props.noAnimation) {
          if (layout.current && content.current) {
            layoutAnimation.current = gsap.to(layout.current, { opacity: 1, duration: 0.2, onComplete: () => {
              if (isMounted.current) {
                setOpened(true)
              }
            }})

            contentAnimation.current = gsap.fromTo(content.current, { y: 200 }, { y: 0, duration: 0.2, onComplete: () => {
              if (isMounted.current) {
                setOpened(true)
              }
            }})
          }
        } else {
          setOpened(true)
        }
      }
    } else {
      if (opened) {
        if (!props.noAnimation) {
          if (layout.current && content.current) {
            layoutAnimation.current = gsap.to(layout.current, { opacity: 0, duration: 0.2, onComplete: () => {
              if (isMounted.current) {
                document.body.classList.remove('modal-open')
                setOpened(false)
              }
            }})

            contentAnimation.current = gsap.fromTo(content.current, { y: 0 }, { y: 200, duration: 0.2, onComplete: () => {
              if (isMounted.current) {
                document.body.classList.remove('modal-open')
                setOpened(false)
              }
            }})
          }
        } else {
          document.body.classList.remove('modal-open')
          setOpened(false)
        }
      }
    }

    return () => {
      layoutAnimation.current?.kill()
      contentAnimation.current?.kill()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, opened])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    processShadow(event.currentTarget)

    if (props.onScrollEnd) {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
      if (scrollTop + clientHeight >= scrollHeight) {
        props.onScrollEnd(false)
      }
    }
  }

  const processShadow = (div: HTMLDivElement) => {
    const { scrollTop, clientHeight, scrollHeight } = div

    if (scrollTop + clientHeight + 2 >= scrollHeight) {
      setScrollBottom(true)
    } else {
      if (scrollBottom === true) {
        setScrollBottom(false)
      }
    }
  }

  const handleClose = () => {
    if (props.onClose && !props.doNotClose) {
      props.onClose()
    }
  }

  const modalRoot = typeof document !== 'undefined' ? document.getElementById('modal-root') : null
  
  return (props.open || opened) && modalRoot ? createPortal(
    <div ref={layout} className={cn(styles.layout, {[styles.noAnimation]: props.noAnimation})}>
      <div ref={content} className={styles.content} onClick={handleClose}>
        <div ref={scrollRef} id={props.scrollId} className={styles.inner} onScroll={handleScroll} onClick={e => e.stopPropagation()}>
          <div>
            <span onClick={handleClose} />
            <div className={styles.dialog}>
              {props.children}
            </div>
          </div>
        </div>
      </div>

      {props.actions ? (
        <div className={cn(styles.actions, {[styles.bottom]: scrollBottom})}>
          {props.actions}
        </div>
      ) : null}

      {props.whiteBack ? (
        <div className={styles.backWhite} />
      ) : null}
    </div>,
  modalRoot as HTMLElement) : null
}

export default AppDialog